.Header {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 58px;
  background-color: #1F2B70;

  .logo {
    color: white;
    font-size: x-large;
    margin-left: 21px;
  }

  .title {
    color: white;
    font-size: larger;
    margin-left: 21px;
  }
}
